// extracted by mini-css-extract-plugin
export var certificateContainer = "SkillCourseCertificate-module--certificateContainer--8b848";
export var certificateContent = "SkillCourseCertificate-module--certificateContent--f6689";
export var certificateContentLeft = "SkillCourseCertificate-module--certificateContentLeft--15b02";
export var certificateContentMiddle = "SkillCourseCertificate-module--certificateContentMiddle--29108";
export var certificateContentRight = "SkillCourseCertificate-module--certificateContentRight--02950";
export var certificateImageContainer = "SkillCourseCertificate-module--certificateImageContainer--bea9e";
export var detailItem = "SkillCourseCertificate-module--detailItem--a489e";
export var headingDesktop = "SkillCourseCertificate-module--headingDesktop--2a8d4";
export var headingMobile = "SkillCourseCertificate-module--headingMobile--bf6c0";
export var lmsScrollbar = "SkillCourseCertificate-module--lms-scrollbar--b990c";
export var tickIcon = "SkillCourseCertificate-module--tickIcon--c8402";
export var title = "SkillCourseCertificate-module--title--ae964";
export var wrapper = "SkillCourseCertificate-module--wrapper--f3a74";