// extracted by mini-css-extract-plugin
export var accordianHeader = "SkillCourseFaq-module--accordianHeader--35766";
export var accordianItem = "SkillCourseFaq-module--accordianItem--cabf4";
export var accordianText = "SkillCourseFaq-module--accordianText--2c961";
export var contactUnschool = "SkillCourseFaq-module--contactUnschool--79a5c";
export var courseFaqContainer = "SkillCourseFaq-module--courseFaqContainer--67780";
export var courseFaqContent = "SkillCourseFaq-module--courseFaqContent--7dcf2";
export var courseFaqContentLeft = "SkillCourseFaq-module--courseFaqContentLeft--e972b";
export var courseFaqContentRight = "SkillCourseFaq-module--courseFaqContentRight--6cd81";
export var faqImageContainer = "SkillCourseFaq-module--faqImageContainer--48fde";
export var headingContainer = "SkillCourseFaq-module--headingContainer--df637";
export var headingDesktop = "SkillCourseFaq-module--headingDesktop--0f346";
export var headingMobile = "SkillCourseFaq-module--headingMobile--3c2dd";
export var iconClassName = "SkillCourseFaq-module--iconClassName--55b36";
export var lmsScrollbar = "SkillCourseFaq-module--lms-scrollbar--225f2";