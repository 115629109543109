import { withPrefix } from "gatsby";
import React from "react";
import { isSSR } from "../../../helpers/global";

const Seo = (props) => {
  const {
    title,
    description,
    keywords,
    robots,
    httpEquiv,
    language,
    revisitAfter,
    author,
    schemaMarkup,
    children,
  } = props;

  return (
    <>
      <html lang="en" />
      <title>{title}</title>
      <meta name="title" content={title} />
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {robots ? (
        <meta name="robots" content={robots} />
      ) : (
        <meta name="robots" content="index,follow" />
      )}
      {httpEquiv && <meta http-equiv="Content-Type" content={httpEquiv} />}
      {language && <meta name="language" content={language} />}
      {revisitAfter ? (
        <meta name="revisit-after" content={revisitAfter} />
      ) : (
        <meta name="revisit-after" content="1 day" />
      )}
      {author ? (
        <meta name="author" content={author} />
      ) : (
        <meta name="author" content="Unschool" />
      )}
      <meta name="document-type" content="Public" />
      <meta name="Copyright" content="Unschool" />
      <meta name="googlebot" content="index,follow" />
      <meta name="YahooSeeker" content="index,follow" />
      <meta name="msnbot" content="index,follow" />
      <meta name="allow-search" content="yes" />
      <meta name="document-distribution" content="Global" />
      <meta name="geo.region" content="IN-KA" />
      <meta name="geo.placename" content="Hyderabad" />
      <meta name="geo.position" content="17.429891;78.370295" />
      <meta name="ICBM" content="17.429891, 78.370295" />
      <meta name="theme-color" content="#fff" />
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="/" />
      <meta charSet="utf-8 " />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />

      <link
        rel="apple-touch-icon"
        sizes="256x256"
        href={`${withPrefix("/")}img/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix("/")}img/apple-touch-icon.png`}
        sizes="256x256"
      />

      <script async>window.apiBaseUrl = ``;</script>
      <script
        type="application/javascript"
        src="https://sdki.truepush.com/sdk/v2.0.2/app.js"
        async
      />

      {schemaMarkup &&
        schemaMarkup.map((item, index) => (
          <script key={index} type="application/ld+json">
            {JSON.stringify(item)}
          </script>
        ))}

      {!isSSR() && (
        <script async>
          {`window['_fs_debug'] = false;
         window['_fs_host'] = 'fullstory.com';
         window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
         window['_fs_org'] = '10XBTH';
         window['_fs_namespace'] = 'FS';
         (function(m, n, e, t, l, o, g, y) {
             if (e in m) {
                 if (m.console && m.console.log) {
                     m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
                 }
                 return;
             }
             g = m[e] = function(a, b, s) {
                 g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
             };
             g.q = [];
             o = n.createElement(t);
             o.async = 1;
             o.crossOrigin = 'anonymous';
             o.src = 'https://' + _fs_script;
             y = n.getElementsByTagName(t)[0];
             y.parentNode.insertBefore(o, y);
             g.identify = function(i, v, s) {
                 g(l, {
                     uid: i
                 }, s);
                 if (v) g(l, v, s)
             };
             g.setUserVars = function(v, s) {
                 g(l, v, s)
             };
             g.event = function(i, v, s) {
                 g('event', {
                     n: i,
                     p: v
                 }, s)
             };
             g.anonymize = function() {
                 g.identify(!!0)
             };
             g.shutdown = function() {
                 g("rec", !1)
             };
             g.restart = function() {
                 g("rec", !0)
             };
             g.log = function(a, b) {
                 g("log", [a, b])
             };
             g.consent = function(a) {
                 g("consent", !arguments.length || a)
             };
             g.identifyAccount = function(i, v) {
                 o = 'account';
                 v = v || {};
                 v.acctId = i;
                 g(o, v)
             };
             g.clearUserCookie = function() {};
             g.setVars = function(n, p) {
                 g('setVars', [n, p]);
             };
             g._w = {};
             y = 'XMLHttpRequest';
             g._w[y] = m[y];
             y = 'fetch';
             g._w[y] = m[y];
             if (m[y]) m[y] = function() {
                 return g._w[y].apply(this, arguments)
             };
             g._v = "1.3.0";
         })(window, document, window['_fs_namespace'], 'script', 'user');`}
        </script>
      )}

      {!isSSR() && (
        <script async>
          {`(function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-NT2RN94")`}
        </script>
      )}

      {!isSSR() && (
        <script async>
          {(function (h, o, t, j, a, r) {
            h.hj =
              h.hj ||
              function () {
                (h.hj.q = h.hj.q || []).push(arguments);
              };
            h._hjSettings = { hjid: 2100734, hjsv: 6 };
            a = o.getElementsByTagName("head")[0];
            r = o.createElement("script");
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
          })(
            window,
            document,
            "https://static.hotjar.com/c/hotjar-",
            ".js?sv="
          )}
        </script>
      )}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-V0SKH7QY59"
      />

      {!isSSR() && (
        <script>
          {(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-NT2RN94")}
        </script>
      )}
      {children}
      {!isSSR() && (
        <>
          <script src="https://js.chargebee.com/v2/chargebee.js" />
          <script
            async
            src="https://checkout.razorpay.com/v1/checkout.js"
            key={1}
          />
        </>
      )}
    </>
  );
};

export default Seo;
