// extracted by mini-css-extract-plugin
export var courseDetails = "SkillCoursePurchaseCard-module--courseDetails--79471";
export var courseIncludesHeading = "SkillCoursePurchaseCard-module--courseIncludesHeading--01beb";
export var coursePurchaseStatus = "SkillCoursePurchaseCard-module--coursePurchaseStatus--2d46c";
export var coursePurchasebutton = "SkillCoursePurchaseCard-module--coursePurchasebutton--300dc";
export var coursePurchasebuttonText = "SkillCoursePurchaseCard-module--coursePurchasebuttonText--41568";
export var courseStats = "SkillCoursePurchaseCard-module--courseStats--efd0e";
export var courseStatsBottom = "SkillCoursePurchaseCard-module--courseStatsBottom--67cd8";
export var courseStatsBottomItem = "SkillCoursePurchaseCard-module--courseStatsBottomItem--e6cca";
export var courseStatsBottomText = "SkillCoursePurchaseCard-module--courseStatsBottomText--30e15";
export var courseStatsContent = "SkillCoursePurchaseCard-module--courseStatsContent--1ddba";
export var courseStatsItem = "SkillCoursePurchaseCard-module--courseStatsItem--e76a2";
export var courseStatsText = "SkillCoursePurchaseCard-module--courseStatsText--fa4e6";
export var courseStatsTop = "SkillCoursePurchaseCard-module--courseStatsTop--69618";
export var courseStatsTopItem = "SkillCoursePurchaseCard-module--courseStatsTopItem--57bbc";
export var courseStatsTopText = "SkillCoursePurchaseCard-module--courseStatsTopText--19052";
export var courseVideo = "SkillCoursePurchaseCard-module--courseVideo--e8e11";
export var lmsScrollbar = "SkillCoursePurchaseCard-module--lms-scrollbar--74668";
export var purchaseButton = "SkillCoursePurchaseCard-module--purchaseButton--a5bae";
export var purchaseButtonContainer = "SkillCoursePurchaseCard-module--purchaseButtonContainer--17f38";
export var purchaseButtonText = "SkillCoursePurchaseCard-module--purchaseButtonText--e9737";
export var purchaseContainerDesktop = "SkillCoursePurchaseCard-module--purchaseContainerDesktop--3e707";
export var purchaseContainerMobile = "SkillCoursePurchaseCard-module--purchaseContainerMobile--b5543";
export var title = "SkillCoursePurchaseCard-module--title--3d215";
export var videoContainer = "SkillCoursePurchaseCard-module--videoContainer--f0c5d";
export var videoIframe = "SkillCoursePurchaseCard-module--videoIframe--68144";