// extracted by mini-css-extract-plugin
export var breadcrumbs = "SkillCoursePageHero-module--breadcrumbs--80804";
export var courseDescription = "SkillCoursePageHero-module--courseDescription--8aef8";
export var courseHeroContainer = "SkillCoursePageHero-module--courseHeroContainer--5d7b0";
export var courseHeroContentLeft = "SkillCoursePageHero-module--courseHeroContentLeft--55b5e";
export var courseHeroContentRight = "SkillCoursePageHero-module--courseHeroContentRight--3f8ed";
export var coursePurchaseContainerDesktop = "SkillCoursePageHero-module--coursePurchaseContainerDesktop--e9c29";
export var coursePurchaseContainerMobile = "SkillCoursePageHero-module--coursePurchaseContainerMobile--53c05";
export var courseRatingDesktop = "SkillCoursePageHero-module--courseRatingDesktop--33d35";
export var courseRatingMobile = "SkillCoursePageHero-module--courseRatingMobile--5a28a";
export var courseStatsItem = "SkillCoursePageHero-module--courseStatsItem--5c66e";
export var courseStatsMobile = "SkillCoursePageHero-module--courseStatsMobile--9e5e0";
export var heroTitle = "SkillCoursePageHero-module--heroTitle--ecdca";
export var instructorName = "SkillCoursePageHero-module--instructorName--4f838";
export var instructorPhoto = "SkillCoursePageHero-module--instructorPhoto--85a5f";
export var instructorProfile = "SkillCoursePageHero-module--instructorProfile--5786f";
export var lmsScrollbar = "SkillCoursePageHero-module--lms-scrollbar--c5a28";
export var wrapper = "SkillCoursePageHero-module--wrapper--9671e";