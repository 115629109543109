import React, { useMemo } from "react";
import { Col, Typography } from "antd";
import PropTypes from "prop-types";

import * as classes from "./SkillCoursePurchaseCard.module.scss";
import clockIcon from "../images/clock.svg";
import infinityIcon from "../images/infinity.svg";
import certificateIon from "../images/certificate.svg";
import groupIcon from "../images/groups.svg";
import communityIcon from "../images/community.svg";
import chaptersIcon from "../images/chapters.svg";
import internshipIcon from "../images/internship.svg";
import levelIcon from "../images/level.svg";
import languageIcon from "../images/language.svg";
import { capitalizeFirstLetter } from "../../../utils/functions";
import SkillCoursePurchaseButton from "../SkillCoursePurchaseButton";

const { Title, Paragraph } = Typography;

const SkillCoursePurchaseCardDesktop = (props) => {
  const { course, subscriptionStatusText } = props;

  const courseDurationString = useMemo(
    () => `${course?.duration} ${course?.duration > 1 ? "Hours" : "Hour"}`,
    []
  );

  const lessonsCountString = useMemo(
    () =>
      `${course?.chapters[0].lessons.length} ${
        course?.chapters[0].lessons.length > 1 ? "Lessons" : "Lesson"
      }`,
    []
  );

  return (
    <Col className={classes.purchaseContainerDesktop}>
      <Col className={classes.videoContainer}>
        <iframe
          className={classes.videoIframe}
          src={
            course?.youtubeUrl
              ? course?.youtubeUrl
              : "https://player.vimeo.com/video/818680206?h=e311b4a50a&badge=0&autopause=0&player_id=0&app_id=58479"
          }
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen={true}
          id="iframe"
        />
        <script src="https://player.vimeo.com/api/player.js" />
      </Col>
      <Col className={classes.courseDetails}>
        <Title className={classes.coursePurchaseStatus}>
          {subscriptionStatusText}
        </Title>
        <Col
          className={classes.purchaseButtonContainer}
          id="skill-course-purchase-button-desktop"
        >
          <SkillCoursePurchaseButton
            customClass={classes.purchaseButton}
            customTextClass={classes.purchaseButtonText}
          />
        </Col>
        <Title className={classes.courseIncludesHeading}>
          This Subscription Includes:
        </Title>
        <Col className={classes.courseStats}>
          <Col className={classes.courseStatsTop}>
            <Col className={classes.courseStatsTopItem}>
              <img src={clockIcon} />
              <Paragraph className={classes.courseStatsTopText}>
                200+ Hours
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsTopItem}>
              <img src={infinityIcon} />
              <Paragraph className={classes.courseStatsTopText}>
                New Courses Every Month
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsTopItem}>
              <img src={certificateIon} />
              <Paragraph className={classes.courseStatsTopText}>
                Completion Certificate
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsTopItem}>
              <img src={groupIcon} />
              <Paragraph className={classes.courseStatsTopText}>
                Learning groups
              </Paragraph>
            </Col>
          </Col>
          <Col className={classes.courseStatsBottom}>
            <Col className={classes.courseStatsBottomItem}>
              <img src={levelIcon} />
              <Paragraph className={classes.courseStatsBottomText}>
                {capitalizeFirstLetter(course?.level)} Level
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsBottomItem}>
              <img src={languageIcon} />
              <Paragraph className={classes.courseStatsBottomText}>
                Language: English
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsBottomItem}>
              <img src={chaptersIcon} />
              <Paragraph className={classes.courseStatsBottomText}>
                {lessonsCountString}, {courseDurationString}
              </Paragraph>
            </Col>
            <Col className={classes.courseStatsBottomItem}>
              <img src={communityIcon} />
              <Paragraph className={classes.courseStatsBottomText}>
                {course?.totalLearnersCount} Learners
              </Paragraph>
            </Col>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

SkillCoursePurchaseCardDesktop.propTypes = {
  course: PropTypes.object,
  subscriptionStatusText: PropTypes.string,
};

export default SkillCoursePurchaseCardDesktop;
