import React, { useMemo } from "react";
import { Col, Typography } from "antd";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import * as classes from "./SkillCoursePageHero.module.scss";
import SkillCoursePurchaseCardDesktop from "../SkillCoursePurchaseCard/SkillCoursePurchaseCardDesktop";
import SkillCoursePurchaseCardMobile from "../SkillCoursePurchaseCard/SkillCoursePurchaseCardMobile";
import ProfileAvatar from "../../ProfileAvatar";
import StarRatings from "../../StarRatings";
import shieldIcon from "../images/shield.svg";
import levelIcon from "../images/level.svg";
import languageIcon from "../images/language.svg";
import communityIon from "../images/community.svg";
import chaptersIcon from "../images/chapters.svg";

const { Title, Paragraph } = Typography;

const SkillCourseHero = (props) => {
  const { course, coach, subscriptionStatusText } = props;

  const lessonsCountString = useMemo(
    () =>
      `${course?.chapters[0].lessons.length} ${
        course?.chapters[0].lessons.length > 1 ? "Lessons" : "Lesson"
      }`,
    []
  );

  const courseDurationString = useMemo(
    () => `${course?.duration} ${course?.duration > 1 ? "Hours" : "Hour"}`,
    []
  );

  const getCourseRating = useMemo((course) => {
    if (course?.category?.slug === "software-and-technology") {
      return {
        reviewers: "2500+",
        rating: 4.1,
      };
    }
    return {
      reviewers: "2000+",
      rating: 4.2,
    };
  }, []);

  return (
    <Col className={classes.courseHeroContainer}>
      <Col className={classes.wrapper}>
        <Col className={classes.courseHeroContentLeft}>
          <Col className={classes.breadcrumbs}>
            <a href="/">Browse</a>
            <Link to={`/categories/${course?.category?.slug}`}>
              {course?.category?.name}
            </Link>
            <span>{course?.title}</span>
          </Col>
          <Title className={classes.heroTitle}>{course?.title}</Title>
          <Col className={classes.courseRatingMobile}>
            <StarRatings
              rating={course?.averageRating}
              reviewers={course?.totalReviewsCount}
              spacing={4}
            />
          </Col>
          <Col className={classes.instructorProfile}>
            <ProfileAvatar
              size={32}
              customClass={classes.instructorPhoto}
              profilePicture={{
                url: coach?.profilePicture?.url,
              }}
              firstName={coach?.firstName}
              lastName={coach?.firstLast}
              alt={"Image"}
            />
            <Paragraph className={classes?.instructorName}>
              {coach?.firstName}
            </Paragraph>
          </Col>
          <Col className={classes.courseStatsMobile}>
            <Col className={classes.courseStatsItem}>
              <img src={shieldIcon} alt="icon" />
              <span>Lifelong Access</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img src={levelIcon} alt="icon" />
              <span>Beginner Level</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img src={languageIcon} alt="icon" />
              <span>Language: English</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img src={communityIon} alt="icon" />
              <span>{course?.totalLearnersCount} Learners</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img src={chaptersIcon} alt="icon" />
              <span>
                {lessonsCountString}, {courseDurationString}
              </span>
            </Col>
          </Col>
          <Paragraph className={classes.courseDescription}>
            {course?.shortDescription}
          </Paragraph>
          <Col className={classes.courseRatingDesktop}>
            <StarRatings
              rating={getCourseRating.rating}
              reviewers={getCourseRating.reviewers}
              spacing={4}
            />
          </Col>
          <Col className={classes.coursePurchaseContainerMobile}>
            <SkillCoursePurchaseCardMobile
              course={course}
              subscriptionStatusText={subscriptionStatusText}
            />
          </Col>
        </Col>
        <Col className={classes.courseHeroContentRight}>
          <Col className={classes.coursePurchaseContainerDesktop}>
            <SkillCoursePurchaseCardDesktop
              course={course}
              subscriptionStatusText={subscriptionStatusText}
            />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

SkillCourseHero.propTypes = {
  course: PropTypes.object,
  coach: PropTypes.object,
  subscriptionStatusText: PropTypes.string,
};

export default SkillCourseHero;
