// extracted by mini-css-extract-plugin
export var coachContainer = "SkillCourseCoach-module--coachContainer--900b2";
export var coachContent = "SkillCourseCoach-module--coachContent--4dd5a";
export var coachDetails = "SkillCourseCoach-module--coachDetails--0e0e8";
export var coachDetailsDesktop = "SkillCourseCoach-module--coachDetailsDesktop--08c5a";
export var coachDetailsMobile = "SkillCourseCoach-module--coachDetailsMobile--9f279";
export var coachImage = "SkillCourseCoach-module--coachImage--e21ea";
export var coachIntro = "SkillCourseCoach-module--coachIntro--dc21c";
export var coachName = "SkillCourseCoach-module--coachName--efa41";
export var courseStats = "SkillCourseCoach-module--courseStats--47951";
export var heading = "SkillCourseCoach-module--heading--c120c";
export var lmsScrollbar = "SkillCourseCoach-module--lms-scrollbar--8b74f";
export var statsIcon = "SkillCourseCoach-module--statsIcon--a0f7b";
export var statsList = "SkillCourseCoach-module--statsList--b7afd";
export var wrapper = "SkillCourseCoach-module--wrapper--91b5d";