import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Col, Typography } from "antd";
import classNames from "classnames";

import * as classes from "./SkillCoursePlanCard.module.scss";
import { DAYS, HAS_NO_FREE_PLAN, MONTHS } from "../../../utils/localization";
import checkCircleIcon from "../images/check-circle.svg";
import { useSelector } from "react-redux";

const { Paragraph } = Typography;

const plansColors = ["#00a780", "#a28bea", "#eab773"];

const SkillCoursePlanCard = (props) => {
  const {
    plan,
    index,
    selectedPlan,
    setSelectedPlan,
    checkUserFreePlanStatus,
    handleChangePlan,
  } = props;

  const { cartDetails } = useSelector((state) => {
    return state;
  });

  const convertDaysToMonth = useCallback((days) => {
    if (days < 30) {
      return { type: DAYS, value: days };
    } else {
      const months = parseInt(days / 30);
      return { type: MONTHS, value: months };
    }
  }, []);

  const validity = useMemo(() => convertDaysToMonth(plan.validity), []);

  const handleSelect = () => {
    handleChangePlan({
      plan,
      cartDetails,
      callback: () => {
        setSelectedPlan(plan);
      },
    });
  };

  if (!plan?.chargebeeName && checkUserFreePlanStatus === HAS_NO_FREE_PLAN) {
    return null;
  }

  const selected = selectedPlan.id === plan.id;

  return (
    <Col
      className={classNames(classes.planCard, selected && classes.active)}
      onClick={handleSelect}
    >
      <Paragraph
        className={classes.validity}
        style={{ backgroundColor: plansColors[index] }}
      >
        {validity.value}&nbsp;
        {validity.type === DAYS
          ? validity.value > 1
            ? "Days"
            : "Day"
          : validity.value > 1
          ? "Months"
          : "Month"}
      </Paragraph>
      <Paragraph className={classes.price}>
        {plan.chargebeeName === null ? (
          "Free"
        ) : (
          <>
            <span>₹</span>&nbsp;
            {plan.price}
          </>
        )}
      </Paragraph>
      {selected && (
        <img
          className={classes.tickIcon}
          src={checkCircleIcon}
          alt="check circle"
        />
      )}
    </Col>
  );
};

SkillCoursePlanCard.propTypes = {
  plan: PropTypes.object,
  index: PropTypes.number,
  selectedPlan: PropTypes.object,
  setSelectedPlan: PropTypes.func,
  checkUserFreePlanStatus: PropTypes.string,
  handleChangePlan: PropTypes.func,
};

export default SkillCoursePlanCard;
