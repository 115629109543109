// extracted by mini-css-extract-plugin
export var LoadingContainer = "SkillCoursePlansModalContent-module--LoadingContainer--428e5";
export var appliedCoupon = "SkillCoursePlansModalContent-module--appliedCoupon--219f3";
export var appliedCouponIcon = "SkillCoursePlansModalContent-module--appliedCouponIcon--1151b";
export var appliedCouponName = "SkillCoursePlansModalContent-module--appliedCouponName--208e3";
export var appliedCouponText = "SkillCoursePlansModalContent-module--appliedCouponText--0fd21";
export var applyCouponBtn = "SkillCoursePlansModalContent-module--applyCouponBtn--17d8f";
export var applyCouponBtnTextClass = "SkillCoursePlansModalContent-module--applyCouponBtnTextClass--e5a70";
export var billAmount = "SkillCoursePlansModalContent-module--billAmount--9fdbb";
export var billContent = "SkillCoursePlansModalContent-module--billContent--b80cc";
export var billDetails = "SkillCoursePlansModalContent-module--billDetails--8d7d1";
export var billItem = "SkillCoursePlansModalContent-module--billItem--8ce8c";
export var billTitle = "SkillCoursePlansModalContent-module--billTitle--aa55b";
export var billTotal = "SkillCoursePlansModalContent-module--billTotal--62c2f";
export var couponForm = "SkillCoursePlansModalContent-module--couponForm--ea986";
export var couponInput = "SkillCoursePlansModalContent-module--couponInput--28ec7";
export var formItemLeft = "SkillCoursePlansModalContent-module--formItemLeft--add90";
export var formItemRight = "SkillCoursePlansModalContent-module--formItemRight--3a2e0";
export var lmsScrollbar = "SkillCoursePlansModalContent-module--lms-scrollbar--e314b";
export var plansContainer = "SkillCoursePlansModalContent-module--plansContainer--05dcf";
export var plansModalContainer = "SkillCoursePlansModalContent-module--plansModalContainer--6c3fc";
export var subscribeButton = "SkillCoursePlansModalContent-module--subscribeButton--ff12e";
export var subscribeButtonTextClass = "SkillCoursePlansModalContent-module--subscribeButtonTextClass--527c4";
export var totalAmount = "SkillCoursePlansModalContent-module--totalAmount--53216";
export var totalContent = "SkillCoursePlansModalContent-module--totalContent--a6e32";