import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      fontWeight: 600,
    }}
    spin
  />
);

const CustomLoading = () => {
  return <Spin indicator={antIcon} />;
};

export default CustomLoading;
