import React from "react";
import { Col, Typography } from "antd";
import PropTypes from "prop-types";

import * as classes from "./SkillCourseDetails.module.scss";
import tickIcon from "../images/tickIconBlue.svg";

const { Title, Paragraph } = Typography;

const SkillCourseDetails = (props) => {
  const { course } = props;

  return (
    <Col className={classes.courseDetailContainer}>
      <Col className={classes.wrapper}>
        <Col className={classes.courseDetailContentLeft}>
          <Col>
            <Title className={classes.heading}>
              This course will enable you to
            </Title>
            <Col className={classes.detailList}>
              {course?.overviews.map((courseDetailListItem) => (
                <Col key={courseDetailListItem} className={classes.detailItem}>
                  <img src={tickIcon} alt="A dinosaur" />
                  <Paragraph className={classes.detailText}>
                    {courseDetailListItem}
                  </Paragraph>
                </Col>
              ))}
            </Col>
          </Col>
        </Col>
        <Col className={classes.courseDetailContentRight} />
      </Col>
    </Col>
  );
};

SkillCourseDetails.propTypes = {
  course: PropTypes.object,
};

export default SkillCourseDetails;
