import React from "react";
import { Col, Typography } from "antd";
import PropTypes from "prop-types";

import * as classes from "./SkillCoursePurchaseCard.module.scss";
import infinityIcon from "../images/infinity.svg";
import certificateIon from "../images/certificate.svg";
import projectIcon from "../images/projects.svg";
import groupIcon from "../images/groups.svg";
import interShipIcon from "../images/internship.svg";
import SkillCoursePurchaseButton from "../SkillCoursePurchaseButton";

const { Title, Paragraph } = Typography;

const SkillCoursePurchaseCardMobile = (props) => {
  const { course, subscriptionStatusText } = props;

  return (
    <Col className={classes.purchaseContainerMobile}>
      <Col className={classes.purchaseButtonContainer}>
        <Title className={classes.title}>{subscriptionStatusText}</Title>
        <Col
          id="skill-course-purchase-button-mobile"
          className={classes.purchaseButton}
        >
          <SkillCoursePurchaseButton
            customClass={classes.coursePurchasebutton}
            customTextClass={classes.coursePurchasebuttonText}
          />
        </Col>
      </Col>
      <Col className={classes.courseVideo}>
        <Col className={classes.videoContainer}>
          <iframe
            className={classes.videoIframe}
            src={
              course?.youtubeUrl
                ? course?.youtubeUrl
                : "https://player.vimeo.com/video/818680206?h=e311b4a50a&badge=0&autopause=0&player_id=0&app_id=58479"
            }
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen={true}
            id="iframe"
          />
          <script src="https://player.vimeo.com/api/player.js" />
        </Col>

        <Col className={classes.courseStatsContent}>
          <Title className={classes.title}>This course includes:</Title>
          <Col className={classes.courseStatsItem}>
            <img src={infinityIcon} />
            <Paragraph className={classes.courseStatsText}>
              Lifelong Access
            </Paragraph>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={certificateIon} />
            <Paragraph className={classes.courseStatsText}>
              Completion Certificate
            </Paragraph>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={projectIcon} />
            <Paragraph className={classes.courseStatsText}>
              2 Projects
            </Paragraph>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={interShipIcon} />
            <Paragraph className={classes.courseStatsText}>
              Internship Opportunities
            </Paragraph>
          </Col>
          <Col className={classes.courseStatsItem}>
            <img src={groupIcon} />
            <Paragraph className={classes.courseStatsText}>
              Learning groups
            </Paragraph>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

SkillCoursePurchaseCardMobile.propTypes = {
  course: PropTypes.object,
  subscriptionStatusText: PropTypes.string,
};

export default SkillCoursePurchaseCardMobile;
