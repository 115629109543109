import { useMutation } from "@apollo/client";
import { Col, Form, Typography } from "antd";
import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "antd/lib/form/Form";

import * as classes from "./SkillCoursePlansModalContent.module.scss";
import {
  ADD_CART_ITEM,
  APPLY_COUPON,
  CREATE_PLAN_ORDER,
  REMOVE_COUPON,
  RESET_CART,
} from "../../../../graphql/mutations/cart";
import { sortPlansAcend } from "../../../utils/helpers/component";
import {
  cartDetailsActions,
  helperActions,
  modalActions,
  userPlansActions,
} from "../../../utils/redux/actions";
import { variants } from "../../../utils/variants";
import SkillCoursePlanCard from "../SkillCoursePlanCard";
import { checkUserHasFreePlan } from "../../../utils/helpers/component";
import CustomInput from "../../adaptors/CustomInput";
import { CustomButton } from "../../adaptors/CustomButton";
import FormErrorMessage from "../../FormErrorMessage";
import { gqlErrors } from "../../../../graphql/errors";
import { logger } from "../../../helpers/logger";
import voucherIcon from "../images/voucher.svg";
import CustomLoading from "../../adaptors/CustomLoading/CustomLoading";
import { SITE_NAME } from "../../../utils/localization";
import axios from "axios";
import { isSSR } from "../../../helpers/global";

const { Text, Title } = Typography;

const SkillCoursePlansModalContent = () => {
  const { userPlans, cartDetails, user } = useSelector((state) => {
    return state;
  });

  const [cbInstance, setCbInstance] = useState(
    isSSR()
      ? null
      : window.Chargebee.init({
          site: SITE_NAME,
        })
  );
  const [selectedPlan, setSelectedPlan] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [form] = useForm();
  const { setFields } = form;

  const dispatch = useDispatch();

  const {
    openAuthModal,
    closePlansModal,
    openSkillCoursePurchaseSuccessModal,
  } = bindActionCreators(modalActions, dispatch);

  const { openToastMessage } = bindActionCreators(helperActions, dispatch);
  const { updateCartDetails, resetCartDetails, setCartDetails } =
    bindActionCreators(cartDetailsActions, dispatch);
  const { updatePurchaseBill } = bindActionCreators(userPlansActions, dispatch);

  const [addItemToCart] = useMutation(ADD_CART_ITEM);
  const [applyCoupon] = useMutation(APPLY_COUPON);
  const [removeCoupon] = useMutation(REMOVE_COUPON);
  const [resetCart] = useMutation(RESET_CART);
  const [createPlan] = useMutation(CREATE_PLAN_ORDER);

  const plans = useMemo(() => sortPlansAcend([...userPlans.plans]), []);

  const checkUserFreePlanStatus = useMemo(() => {
    return checkUserHasFreePlan(userPlans.plans);
  }, [userPlans.plans]);

  const handleRemoveCoupon = async ({ cartUuId }) => {
    try {
      const { data } = await removeCoupon({
        variables: { cartUuid: cartUuId },
      });
      if (data?.couponRemove) {
        updateCartDetails(data?.couponRemove);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        logger.warn(gqlError);
      }
    }
  };

  const handleApplyCoupon = async ({ coupon, cartUuId }) => {
    try {
      const { data } = await applyCoupon({
        variables: { couponCode: coupon, cartUuid: cartUuId },
      });
      if (data?.couponApply) {
        const couponAppliedCartDetails = data.couponApply;
        updateCartDetails(couponAppliedCartDetails);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (
        gqlError?.message === gqlErrors.messages.couponCodeInvalid ||
        gqlError?.message === gqlErrors.messages.couponCodeAlreadyPresent ||
        gqlError?.message === gqlErrors.messages.cartNotFound
      ) {
        setFields([
          {
            name: "coupon",
            errors: [
              <FormErrorMessage
                hideFormHelperIcon
                key="coupon-error-message"
                type="coupon"
              />,
            ],
          },
        ]);
      } else {
        logger.print(gqlError);
      }
    }
  };

  const onFinish = (values) => {
    let { coupon } = values;
    handleApplyCoupon({ coupon: coupon, cartUuId: cartDetails?.uuid });
  };

  const handleChangePlan = async ({ plan, cartDetails, callback }) => {
    try {
      await resetCart({
        variables: {
          input: {
            cartUuid: cartDetails.uuid,
          },
        },
      });
      const { data } = await addItemToCart({
        variables: cartDetails?.uuid
          ? {
              itemableType: "plan",
              itemableId: plan.id,
              cartUuid: cartDetails?.uuid,
            }
          : {
              itemableType: "plan",
              itemableId: plan.id,
            },
      });
      setCartDetails(data.cartItemAdd);
      callback();
    } catch (error) {
      console.log(error);
      const gqlError = error.graphQLErrors[0];
      if (gqlError) openToastMessage({ variant: variants.error });
    }
  };

  const freeTrialCheckout = async ({
    offlinePayment,
    chargebeeName,
    cartUuid,
    cartDetails,
  }) => {
    try {
      const { data } = await createPlan({
        variables: {
          input: {
            start: true,
            cartUuid: cartUuid,
            offlinePayment,
            chargebeeName,
          },
        },
      });
      updatePurchaseBill(cartDetails);
      await resetCart({
        variables: {
          input: {
            cartUuid: cartDetails.uuid,
          },
        },
      });
      if (data.startPlan) {
        openSkillCoursePurchaseSuccessModal();
        closePlansModal();
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        console.log(error);
        logger.warn(gqlError);
      }
    }
  };

  const planCheckout = async ({
    chargebeeName,
    getChargebeeId,
    cartDetails,
  }) => {
    cbInstance.openCheckout({
      hostedPage: async () => {
        try {
          const response = await axios.get(
            `${process.env.GATSBY_CHARGEBEE_API_ENDPOINT}/generate_checkout_new_url?plan_id=${chargebeeName}&chargebee_id=${getChargebeeId}`
          );
          return response.data;
        } catch (error) {
          console.log(error);
        }
      },
      success: async (hostedPageId) => {
        updatePurchaseBill(cartDetails);
        const { data } = await resetCart({
          variables: {
            input: {
              cartUuid: cartDetails.uuid,
            },
          },
        });
        setCartDetails(data?.clearCart ? data?.clearCart : {});
        openSkillCoursePurchaseSuccessModal();
        closePlansModal();
      },
      close: () => {
        console.log("checkout new closed");
      },
      step(step) {
        console.log("checkout", step);
      },
    });
  };

  const handleCheckout = ({ cartDetails }) => {
    const isUserSignedIn = user?.isUserLoggedIn;
    if (!isUserSignedIn) {
      openAuthModal();
    } else {
      const plan = cartDetails?.cartItems[0];
      if (!plan?.itemable?.chargebeeName) {
        freeTrialCheckout({
          offlinePayment: false,
          chargebeeName: null,
          cartUuid: cartDetails?.uuid,
          cartDetails,
        });
      } else if (
        plan?.itemable?.chargebeeName &&
        cartDetails?.totalAmount === 0
      ) {
        freeTrialCheckout({
          offlinePayment: true,
          chargebeeName: plan?.itemable?.chargebeeName,
          cartUuid: cartDetails?.uuid,
          cartDetails,
        });
      } else {
        planCheckout({
          chargebeeName: plan?.itemable?.chargebeeName,
          getChargebeeId: user?.userDetails?.getChargebeeId,
          cartDetails,
        });
      }
    }
  };

  useEffect(() => {
    setSelectedPlan(plans[plans.length - 1]);
  }, []);

  return (
    <Col className={classes.plansModalContainer}>
      {modalLoading ? (
        <Col className={classes.LoadingContainer}>
          <CustomLoading />
        </Col>
      ) : (
        <>
          <Col className={classes.plansContainer}>
            {plans?.map((plan, index) => {
              return (
                <SkillCoursePlanCard
                  key={plan?.id}
                  plan={plan}
                  index={index}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                  checkUserFreePlanStatus={checkUserFreePlanStatus}
                  handleChangePlan={handleChangePlan}
                />
              );
            })}
          </Col>
          <Col className={classes.billDetails}>
            <Title className={classes.billTitle}>Bill Details</Title>
            <Col className={classes.billItem}>
              <Text className={classes.billContent}>Subtotal</Text>
              <Text className={classes.billAmount}>
                ₹{cartDetails?.subTotalAmount}
              </Text>
            </Col>
            <Col className={classes.billItem}>
              <Text className={classes.billContent}>Total Discount</Text>
              <Text className={classes.billAmount}>
                ₹{cartDetails?.discountAmount}
              </Text>
            </Col>
            <Col className={classes.billItem}>
              <Text className={classes.billContent}>GST 18%</Text>
              <Text className={classes.billAmount}>
                ₹{cartDetails?.taxAmount}
              </Text>
            </Col>
            <Col className={classes.billTotal}>
              <Text className={classes.totalContent}>Items total</Text>
              <Text className={classes.totalAmount}>
                ₹{cartDetails?.totalAmount}
              </Text>
            </Col>
          </Col>
          <Col>
            {cartDetails?.coupon ? (
              <Col className={classes.appliedCoupon}>
                <img src={voucherIcon} className={classes.appliedCouponIcon} />
                <Text className={classes.appliedCouponName}>
                  {cartDetails?.coupon?.code}
                </Text>
                <Text className={classes.appliedCouponText}>applied</Text>
                <CustomButton
                  iconButton
                  size="small"
                  variant={variants.ghostButton}
                  icon="fas fa-trash-alt"
                  onClick={() =>
                    handleRemoveCoupon({ cartUuId: cartDetails?.uuid })
                  }
                />
              </Col>
            ) : (
              <Form
                form={form}
                name="basic"
                layout="inline"
                onFinish={onFinish}
                autoComplete="off"
                className={classes.couponForm}
              >
                <Form.Item
                  className={classes.formItemLeft}
                  name="coupon"
                  rules={[
                    {
                      required: true,
                      message: <FormErrorMessage hideFormHelperIcon />,
                    },
                  ]}
                >
                  <CustomInput
                    formItem
                    variant={variants.textInput}
                    placeholder="Coupon code"
                    customClass={classes.couponInput}
                  />
                </Form.Item>
                <Form.Item className={classes.formItemRight}>
                  <CustomButton
                    customClass={classes.applyCouponBtn}
                    customTextClass={classes.applyCouponBtnTextClass}
                    variant={variants.primaryButton}
                    htmlType="submit"
                    title="Apply"
                  />
                </Form.Item>
              </Form>
            )}
          </Col>
          <Col>
            <CustomButton
              customClass={classes.subscribeButton}
              customTextClass={classes.subscribeButtonTextClass}
              variant={variants.primaryButton}
              htmlType="button"
              title="Subscribe"
              onClick={() => {
                handleCheckout({ selectedPlan, cartDetails });
              }}
            />
          </Col>
        </>
      )}
    </Col>
  );
};

export default SkillCoursePlansModalContent;
