// extracted by mini-css-extract-plugin
export var bill = "SkillCoursePurchaseSuccessModal-module--bill--a23fe";
export var billAmount = "SkillCoursePurchaseSuccessModal-module--billAmount--a8697";
export var billContent = "SkillCoursePurchaseSuccessModal-module--billContent--84a07";
export var billItem = "SkillCoursePurchaseSuccessModal-module--billItem--6ff8c";
export var billTitle = "SkillCoursePurchaseSuccessModal-module--billTitle--9698f";
export var billTotal = "SkillCoursePurchaseSuccessModal-module--billTotal--1a8f4";
export var itemDetail = "SkillCoursePurchaseSuccessModal-module--itemDetail--3453e";
export var lmsScrollbar = "SkillCoursePurchaseSuccessModal-module--lms-scrollbar--0fdd6";
export var planDetails = "SkillCoursePurchaseSuccessModal-module--planDetails--a0d95";
export var planItem = "SkillCoursePurchaseSuccessModal-module--planItem--1c2d3";
export var portalBtn = "SkillCoursePurchaseSuccessModal-module--portalBtn--ffbb1";
export var price = "SkillCoursePurchaseSuccessModal-module--price--fac8b";
export var purchaseDetails = "SkillCoursePurchaseSuccessModal-module--purchaseDetails--77070";
export var successDetails = "SkillCoursePurchaseSuccessModal-module--successDetails--ae597";
export var successDetailsInner = "SkillCoursePurchaseSuccessModal-module--successDetailsInner--6e61f";
export var title = "SkillCoursePurchaseSuccessModal-module--title--bbfc4";
export var totalAmount = "SkillCoursePurchaseSuccessModal-module--totalAmount--7ebf5";
export var totalContent = "SkillCoursePurchaseSuccessModal-module--totalContent--e0545";