import React, { useState } from "react";
import { Col, Typography } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import * as classes from "./SkillCourseFaq.module.scss";
import CustomAccordion from "../../adaptors/CustomAccordion";

const { Title, Paragraph } = Typography;

const faq = [
  {
    header: "I am confused about the courses, who do I contact?",
    text: "If you don’t want to continue, you can cancel your purchase through your account page. Follow the prompts to cancel on the settings page. Please note that there aren’t refunds for parrtially unused membership periods.",
  },
  {
    header: "Is there a demo lecture available?",
    text: "If you don’t want to continue, you can cancel your purchase through your account page. Follow the prompts to cancel on the settings page. Please note that there aren’t refunds for parrtially unused membership periods.",
  },
  {
    header: "How long is the course duration?",
    text: "If you don’t want to continue, you can cancel your purchase through your account page. Follow the prompts to cancel on the settings page. Please note that there aren’t refunds for parrtially unused membership periods.",
  },
  {
    header: "How do I cancel my purchase?",
    text: "If you don’t want to continue, you can cancel your purchase through your account page. Follow the prompts to cancel on the settings page. Please note that there aren’t refunds for parrtially unused membership periods.",
  },
  {
    header: "How do I cancel my purchase?",
    text: "If you don’t want to continue, you can cancel your purchase through your account page. Follow the prompts to cancel on the settings page. Please note that there aren’t refunds for parrtially unused membership periods.",
  },
];

const SkillCourseFaq = (props) => {
  const { course } = props;

  const [activeKey, setActiveKey] = useState(-1);

  return (
    <Col className={classes.courseFaqContainer}>
      <Col className={classes.courseFaqContent}>
        <Title className={classes.headingMobile}>
          Got A <br /> Question?
        </Title>
        <Col className={classes.courseFaqContentLeft}>
          <Title className={classes.headingDesktop}>
            Got A <br /> Question?
          </Title>
          <Col className={classes.faqImageContainer}>
            <StaticImage
              loading="lazy"
              src="../images/faqImage.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="certificate"
            />
          </Col>
        </Col>
        <Col className={classes.courseFaqContentRight}>
          {course?.faqs?.map((faq, index) => (
            <CustomAccordion
              containerClassName={classes.accordianItem}
              headerClassName={classes.accordianHeader}
              textClassName={classes.accordianText}
              headingContainerClassName={classes.headingContainer}
              iconClassName={classes.iconClassName}
              key={index}
              header={faq.question}
              text={faq.answer}
              activeKey={activeKey}
              currentKey={index}
              setActiveKey={() =>
                setActiveKey((state) => (state === index ? -1 : index))
              }
            />
          ))}
          <Paragraph className={classes.contactUnschool}>
            Still stuck with your doubt? Reach out to us on support@unschool.in
          </Paragraph>
        </Col>
      </Col>
    </Col>
  );
};

SkillCourseFaq.propTypes = {
  course: PropTypes.object,
};

export default SkillCourseFaq;
