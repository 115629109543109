// extracted by mini-css-extract-plugin
export var bebasUnderlineHeader = "SkillCourseDetails-module--bebas-underline-header--f01d6";
export var bebasUnderlineHeaderContainer = "SkillCourseDetails-module--bebas-underline-header-container--b9be4";
export var bebasUnderlineHeaderUnderline = "SkillCourseDetails-module--bebas-underline-header-underline--d313b";
export var courseCard = "SkillCourseDetails-module--course-card--c0fae";
export var courseDetailContainer = "SkillCourseDetails-module--courseDetailContainer--3d006";
export var courseDetailContentLeft = "SkillCourseDetails-module--courseDetailContentLeft--d7d2b";
export var courseDetailContentRight = "SkillCourseDetails-module--courseDetailContentRight--9cfc3";
export var coursesListContainer = "SkillCourseDetails-module--courses-list-container--4cc5e";
export var coursesScrollButton = "SkillCourseDetails-module--courses-scroll-button--ad992";
export var coursesScrollContainer = "SkillCourseDetails-module--courses-scroll-container--deedf";
export var detailItem = "SkillCourseDetails-module--detailItem--785de";
export var detailList = "SkillCourseDetails-module--detailList--ce9ae";
export var detailText = "SkillCourseDetails-module--detailText--977f4";
export var heading = "SkillCourseDetails-module--heading--ebb46";
export var lmsScrollbar = "SkillCourseDetails-module--lms-scrollbar--db8f1";
export var sectionPillHeaderDesktop = "SkillCourseDetails-module--section-pill-header-desktop--7469f";
export var tickImage = "SkillCourseDetails-module--tickImage--5efe6";
export var wrapper = "SkillCourseDetails-module--wrapper--1b29a";