import React from "react";
import { Col, Typography } from "antd";
import PropTypes from "prop-types";

import * as classes from "./SkillCourseBottomPurchaseCard.module.scss";
import starIcon from "../images/star.svg";
import SkillCoursePurchaseButton from "../SkillCoursePurchaseButton";

const { Title, Paragraph } = Typography;

const SkillCourseBottomPurchaseCard = (props) => {
  const { course, userSubscriptionStatusText } = props;

  return (
    <Col className={classes.purchaseContainer}>
      <Col className={classes.purchaseContent}>
        <Col>
          <Title className={classes.courseName}>{course?.title}</Title>
          <Title className={classes.courseStatus}>
            {userSubscriptionStatusText}
          </Title>
          <Paragraph className={classes.courseRating}>
            <img src={starIcon} alt="icon" />
            &nbsp;{course?.averageRating}&nbsp;(
            {course?.totalLearnersCount}&nbsp;ratings)
          </Paragraph>
        </Col>
        <Col>
          <SkillCoursePurchaseButton
            customClass={classes.purchaseButton}
            customTextClass={classes.purchaseButtonText}
          />
        </Col>
      </Col>
    </Col>
  );
};

SkillCourseBottomPurchaseCard.propTypes = {
  course: PropTypes.object,
  userSubscriptionStatusText: PropTypes.string,
};

export default SkillCourseBottomPurchaseCard;
