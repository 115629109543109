import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CustomModal } from "../../components/CustomModal";

import LayoutWithOutPadding from "../../components/LayoutWithOutPadding";
import SkillCourseBottomPurchaseCard from "../../components/SkillCourse1.0/SkillCourseBottomPurchaseCard";
import SkillCourseCertificate from "../../components/SkillCourse1.0/SkillCourseCertificate";
import SkillCourseCoach from "../../components/SkillCourse1.0/SkillCourseCoach";
import SkillCourseDetails from "../../components/SkillCourse1.0/SkillCourseDetails";
import SkillCourseFaq from "../../components/SkillCourse1.0/SkillCourseFaq";
import SkillCourseHero from "../../components/SkillCourse1.0/SkillCourseHero";
import SkillCoursePurchaseSuccessModalContent from "../../components/SkillCourse1.0/SkillCoursePurchaseSuccessModalContent";
import {
  checkUserHasFreePlan,
  checkUserHasSubscribedToPlan,
  skillCourseSubscriptionStatus,
  UserUsingFreeTrial,
} from "../../utils/helpers/component";
import { SENIOR_COACH } from "../../utils/localization";
import internshipCourseSeoDetails from "../../constants/internshipCourseSeoDetails";
import Seo from "../../components/SkillCourse1.0/Seo";
import { isSSR } from "../../helpers/global";

const SkillCourse = (props) => {
  const [showBottomPurchaseCard, setShowBottomPurchaseCard] = useState(false);

  const { pageContext } = props;
  const { course } = pageContext;

  const coach = useMemo(
    () =>
      course?.coaches.filter((coach) => coach?.userType === SENIOR_COACH)[0],
    []
  );

  const { user, userPlans, modals } = useSelector((state) => state);

  const userSubscriptionStatusText = useMemo(() => {
    const subscriptionStatus = checkUserHasSubscribedToPlan(user.userDetails);
    const isOnFreeTrial = UserUsingFreeTrial(user.userDetails);
    const freePlanApplicationStatus = checkUserHasFreePlan(userPlans.plans);
    return skillCourseSubscriptionStatus(
      subscriptionStatus,
      freePlanApplicationStatus,
      isOnFreeTrial
    );
  }, [user.userDetails, userPlans.plans]);

  const listenScrollEvent = () => {
    const width = window.innerWidth;
    let elemTop = document
      ?.getElementById(
        width <= 991
          ? "skill-course-purchase-button-mobile"
          : "skill-course-purchase-button-desktop"
      )
      ?.getBoundingClientRect().top;
    if (elemTop < 0) {
      setShowBottomPurchaseCard(true);
    } else {
      setShowBottomPurchaseCard(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <LayoutWithOutPadding>
      <SkillCourseHero
        course={course}
        coach={coach}
        subscriptionStatusText={userSubscriptionStatusText}
      />
      <SkillCourseDetails course={course} />
      <SkillCourseCoach coach={coach} />
      <SkillCourseCertificate />
      <SkillCourseFaq course={course} />
      <CustomModal
        visible={modals.skillCoursePurchaseSuccess}
        onCancel={() => {
          window.location.reload();
        }}
        width="fit-content"
      >
        <SkillCoursePurchaseSuccessModalContent />
      </CustomModal>
      {showBottomPurchaseCard && (
        <SkillCourseBottomPurchaseCard
          course={course}
          userSubscriptionStatusText={userSubscriptionStatusText}
        />
      )}
    </LayoutWithOutPadding>
  );
};

export function Head(props) {
  const { pageContext } = props;
  const { course } = pageContext;
  const seoTags = internshipCourseSeoDetails[course?.slug];
  const pageTitle = seoTags?.metaTags?.titleMetaTagContent ?? course?.title;
  return (
    <Seo
      title={pageTitle}
      description={seoTags?.metaTags?.descriptionMetaTagContent}
      keywords={seoTags?.metaTags?.keywordsMetaTagContent}
      robots={seoTags?.metaTags?.robotsMetaTagContent}
      httpEquiv={seoTags?.metaTags?.httpEquivMetaTagContent}
      language={seoTags?.metaTags?.languageMetaTagContent}
      revisitAfter={seoTags?.metaTags?.revisitAfterMetaTagContent}
      author={seoTags?.metaTags?.authorMetaTagContent}
      schemaMarkup={seoTags?.scriptTags}
    />
  );
}

export default SkillCourse;
